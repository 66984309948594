import { doesThisUserIsIclUser } from "utils/CurrentUserUtil";

const dev = {

  // API_ENDPOINT_URL: "http://192.168.0.244:8000/api",
  // API_BASE_URL: "http://192.168.0.244:8000/api",
  // PDF_API_BASE_URL: "http://192.168.0.244:8000/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "http://192.168.0.244:8000/api",
  // API_END_POINT_FOR_PDF: "http://192.168.0.244:8000/",
  // API_END_POINT_FOR_IMG: "http://192.168.0.244:8000/",
  // API_END_POINT_FOR_USSD_PUSH_REQUEST: "http://192.168.0.243:30001/api/v1/ussd-push-request-with-param",
  // API_END_POINT_FOR_TELEBIRR_REQUEST: "http://192.168.0.244:8000/api/reports/send-request/telebirr-payment",
  // API_END_POINT_FOR_GET_PATIENT: "http://192.168.0.244:8000/api/patients",
  // API_END_POINT_FOR_TELEBIRR_PAYMENT_TRANSACTION: "http://192.168.0.244:8000/api/reports/telebirr-payment-transaction",
  // API_END_POINT_FOR_CHECK_TELEBIRR_PAYMENT_TRANSACTION: "http://192.168.0.244:8000/api/reports/check/telebirr-payment-transaction",
  // API_END_POINT_FOR_ACCEPT_TELEBIRR_PAYMENT_TRANSACTIONs: "http://192.168.0.244:3000/telebirr-payment-transaction",


  API_ENDPOINT_URL: "http://localhost:8000/api",
  API_BASE_URL: "http://localhost:8000/api",
  PDF_API_BASE_URL: "http://localhost:8000/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "http://localhost:8000/api",
  API_END_POINT_FOR_PDF: "http://localhost:8000/",
  API_END_POINT_FOR_IMG: "http://localhost:8000/",
  API_END_POINT_FOR_USSD_PUSH_REQUEST: "http://192.168.0.243:30001/api/v1/ussd-push-request-with-param",
  API_END_POINT_FOR_GET_PATIENT: "http://localhost:8000/api/patients",
  API_END_POINT_FOR_TELEBIRR_PAYMENT_TRANSACTION: "http://localhost:8000/api/reports/telebirr-payment-transaction",
  API_END_POINT_FOR_ACCEPT_TELEBIRR_PAYMENT_TRANSACTION: "http://localhost:3000/api/reports/accept/telebirr-payment-transaction",

  // API_ENDPOINT_URL: "http://192.168.2.68:8000/api",
  // API_BASE_URL: "http://192.168.2.68:8000/api",
  // PDF_API_BASE_URL: "http://192.168.2.68:8000/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "http://192.168.2.68:8000/api",
  // API_END_POINT_FOR_PDF : "http://192.168.2.68:8000/",
  // API_END_POINT_FOR_IMG : "http://192.168.2.68:8000/"

  // API_ENDPOINT_URL: "https://drsportal.iclpartner.com/api",
  // API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // PDF_API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // API_END_POINT_FOR_PDF : "https://drsportal.iclpartner.com/",
  // API_END_POINT_FOR_IMG : "https://drsportal.iclpartner.com/",

  // API_ENDPOINT_URL : "https://backendtest.iclpartner.com/api",
  // API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // API_END_POINT_FOR_PDF : "http://localhost:8000/",
  // API_END_POINT_FOR_IMG : "http://localhost:8000/"
};

const prod = {
  API_ENDPOINT_URL: "https://backendtest.iclpartner.com/api",
  API_BASE_URL: "https://backendtest.iclpartner.com/api",
  PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  API_END_POINT_FOR_PDF: "https://backendtest.iclpartner.com/",
  API_END_POINT_FOR_IMG: "https://backendtest.iclpartner.com/",
  API_END_POINT_FOR_USSD_PUSH_REQUEST: "http://192.168.0.243:30001/api/v1/ussd-push-request-with-param",
  API_END_POINT_FOR_TELEBIRR_REQUEST: "https://backendtest.iclpartner.com/api/reports/send-request/telebirr-payment",
  API_END_POINT_FOR_GET_PATIENT: "https://backendtest.iclpartner.com/api/patients",
  API_END_POINT_FOR_CHECK_TELEBIRR_PAYMENT_TRANSACTION: "https://backendtest.iclpartner.com/api/reports/check/telebirr-payment-transaction",
  API_END_POINT_FOR_TELEBIRR_PAYMENT_TRANSACTION: "https://backendtest.iclpartner.com/api/reports/telebirr-payment-transaction"
  // API_ENDPOINT_URL: "https://drsportal.iclpartner.com/api",
  // API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // PDF_API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // API_END_POINT_FOR_PDF : "https://drsportal.iclpartner.com/",
  // API_END_POINT_FOR_IMG : "https://drsportal.iclpartner.com/",
};

const test = {
  API_ENDPOINT_URL: "https://backendtest.iclpartner.com/api",
  API_BASE_URL: "https://backendtest.iclpartner.com/api",
  PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  API_END_POINT_FOR_PDF: "https://backendtest.iclpartner.com/",
  API_END_POINT_FOR_IMG: "https://drsportal.iclpartner.com/",
  API_END_POINT_FOR_TELEBIRR_REQUEST: "https://backendtest.iclpartner.com/api/reports/send-request/telebirr-payment",
  API_END_POINT_FOR_GET_PATIENT: "https://backendtest.iclpartner.com/api/patients",
  API_END_POINT_FOR_CHECK_TELEBIRR_PAYMENT_TRANSACTION: "http://192.168.0.244:8000/api/reports/check/telebirr-payment-transaction",
  API_END_POINT_FOR_TELEBIRR_PAYMENT_TRANSACTION: "https://backendtest.iclpartner.com/api/reports/telebirr-payment-transaction"
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const APP_NAME = "ICL";
export const IMG_PATH = "/img/ICL/";
export const ONLY_FOR_CURRENT_COMPANY_LABEL = "Is ICL uSER";
export const LOGIS_BASE_URL = "https://cerbalancet-africa.legasi.net/external/icl";
export const ALLOWED_FOR_AGENCIES = false;
export const EXTRA_FEE_FOR_STAT_TESTS = 495;
export const EXTRA_FEE_FOR_QUEUE_PRIORITY = 890;
export const CONFIG_LABEL = doesThisUserIsIclUser() ? "Config" : "Test Menu";
export const TV_SCREEN_LABEL = "Tv screen results";
export const SCREENS_LABEL = "SCREENS";
export const PAYMENT_LABEL = doesThisUserIsIclUser() ? "Income" : "Payment";
export const LOGIN_LOGO = "/login-logo.png";
export const env = getEnv();
